// **  Initial State
const initialState = {};

const affiliateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_AFFILIATE_SYSTEM":
      return {
        ...state,
        ...payload,
      };
    case "ADD_AFFILIATE_LEVEL":
      return {
        ...state,
        affiliate_system: [
          ...state.affiliate_system,
          { level: "", bonus: "", ftd_per_month: "" },
        ],
      };
    default:
      return state;
  }
};

export default affiliateReducer;
